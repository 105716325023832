import ImageWithFallback from "components/ImageWithFallback";
import Rating from "components/Rating";
import React, {useEffect, useState} from "react";
import Link from "next/link";
import calcOpenNow from "lib/utils/calcOpenNow";
import cn from "clsx";

export default function LocationCard({data: location, className, row=false, onMouseEnter=()=>{}, showDetails, setSelectedStore, t, i18n}) {
  const [openNow, setOpenNow] = useState(undefined)

  const [name, setName] = useState('LocationName')


  const init = () => {
    if (!!t && location.timeZone !== '') {
      const {isOpen, closeTime} = calcOpenNow(location.business_hours, location.timezone)
      setOpenNow(isOpen ? `${t('home:map_openUntil')} ${closeTime}` : t('home:map_closed'))
    } else {
      setOpenNow('Closed')
    }

    if (location.slug !== '') {
      setName(location.slug)
    }
  }
  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    init()
  }, [t])

  return (
        <div onClick={() => {
          if (setSelectedStore) {
            setSelectedStore(location)
        }
        } } onMouseEnter={onMouseEnter} className={cn([row&&'flex', 'shadow-md rounded overflow-hidden p-4'], className)}>
          {
            showDetails ?
                <div className={cn(["relative after:content-[''] after:block", row?'after:pb-[100%] w-32':'after:pb-[56.25%] mb-4'])}>
                  <ImageWithFallback
                      src={location.avatar_url}
                      alt={location.name}
                      className="object-contain transition hover:brightness-105 cursor-pointer"
                  />
                </div>
                :
                <Link href={`/stores/${encodeURIComponent(name)}/${location.id}` } passHref>
                  <div className={cn(["relative after:content-[''] after:block", row?'after:pb-[100%] w-32':'after:pb-[56.25%] mb-4'])}>
                    <ImageWithFallback
                        src={location.avatar_url}
                        alt={location.name}
                        className="object-contain transition hover:brightness-105 cursor-pointer"
                    />
                  </div>
                </Link>
          }
          <div className="px-4">
            {
              showDetails ?
                  <a>
                    <h4 className="font-bold text-base mb-2 ">
                      {location.name}
                    </h4>
                  </a>
                  :
                  <Link href={`/stores/${encodeURIComponent(name)}/${location.id}`} passHref>
                    <a>
                      <h4 className="font-bold text-base mb-2 min-h-[50px]">
                        {location.name}
                      </h4>
                    </a>
                  </Link>
            }
            <div className="flex flex-nowrap items-center mb-2">
              <div className="text-xs mr-2">
                {location.rating.toFixed(1)}
              </div>
              <Rating
                  value={location.rating || 5}
                  precision={0.5}
                  readOnly
                  className={'mr-2'}
              />
              <div className="text-xs">
                ({location.reviews_count})
              </div>
            </div>
            {
              location.has_curbside_pickup ?
              <div className="text-sm text-primary-dark font-semibold mb-1">
                {!!t ? t('home:map_pickupAvailable') : ''}
              </div> : <div className='mb-1 min-h-[20px]' />
            }
            <div className="text-sm mb-1">
              {openNow}
            </div>
            <div className="text-sm">
              {!!location.distance ? location.distance.toFixed(1): '--'}km
            </div>
          </div>
        </div>
  )
}
