import ImageWithFallback from "components/ImageWithFallback";
import {Chip, Stack} from "@mui/material";
import { checkMobile } from '../../utils/helper'

export default function ArticleCard({data: article}) {
  const timestamp = new Date(article.date + 'z').toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  })

  return (
    <div onClick={() => {
        window.open(article.link, '_blank');
    }}>
      <div className="relative pb-[56.25%] h-0 mb-4">
        <ImageWithFallback src={article.featureImg} alt={article.title} className="object-cover"/>
      </div>
      <div className="px-4 mb-2">
        <h4 className="font-bold text-base mb-2">{article.title}</h4>
        <div className="text-xs font-semibold">{timestamp}</div>
      </div>
      <div className="flex justify-end flex-wrap">
        {
          article.tags.map(tag => (
            <Chip
              key={tag} label={tag} color={'primary'} variant={'outlined'} size={'small'}
              className={'border-[1.5px] rounded-lg font-semibold mt-2 ml-2'}
            />
          ))
        }
      </div>
    </div>
  )
}
