import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from '@fortawesome/fontawesome-svg-core/import.macro'
import DefaultLayout from "layouts/Default";
import Wrapper from "components/blocks/Wrapper";
import Container from "components/blocks/Container";
import HeroSlider from "components/blocks/HeroSlider";
import Link from "next/link";
import {Button} from "@mui/material";
import ItemSlider from "components/blocks/ItemSlider";
import {getFeaturedLocations} from "lib/services/location";
import LocationCard from "components/cards/LocationCard";
import BrandCard from "components/cards/BrandCard";
import ProductCard from "components/cards/ProductCard";
import ArticleCard from "components/cards/ArticleCard";
import {getArticles} from "lib/services/article";
import {wrapper} from "lib/store";
import {getTopBrandsThunk} from "lib/features/brandSlice";
import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useTranslation } from "next-i18next";
import services from "./brands/[brandSlug]/[id]/services";
import { checkMobile } from '../utils/helper'
import {getTopProductsThunk, getTopServicesThunk} from "lib/features/productSlice";
import {getTopLocationsThunk} from "lib/features/mapSlice";
import Head from 'next/head'

export const getServerSideProps = wrapper.getServerSideProps(store => async (context) => {
  const {
    locale
  } = context
    await store.dispatch(getTopBrandsThunk({num:12}))
    await store.dispatch(getTopProductsThunk({num:12}))
    // await store.dispatch(getTopServicesThunk(12))
    await store.dispatch(getTopLocationsThunk({num:20}))

    // const stores = await getFeaturedLocations()
  const learnArticles = (await getArticles({type: 'learns'})).slice(0, 9)
  const newsArticles = (await getArticles({type: 'news'})).slice(0, 9)

  return {
    props: {
      learnArticles,
      newsArticles,
        ...(await serverSideTranslations(locale, ['home', 'common']))
    }
  }
})

export default function Home(props) {
  const {
    stores,
    learnArticles,
    newsArticles,
      locale
  } = props

    const { t, i18n } = useTranslation()
    let topBrands = useSelector(state => state.brand.topBrands)
    let topProducts = useSelector(state => state.product.topProducts)
    let topServices = useSelector(state => state.product.topServices)
    let topLocations = useSelector(state => state.map.topLocations)

    const [localProducts, setLocalProducts] = useState([])
    const [localBrands, setLocalBrands] = useState([])
    const [localStores, setLocalStores] = useState([])

    const [loaded, setLoaded] = useState(false)
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        if (loaded) {
            setIsMobile(checkMobile)
        }
    }, [loaded])
    useEffect(() => {
        if (!!topBrands && topBrands.length > 0 && !!topProducts && topProducts.length > 0 && !!topLocations && topLocations.length > 0) {
            setLoaded(true)
        }
        if (!!topBrands && topBrands.length > 0) {
            setLocalBrands(topBrands)
        }
        if (!!topProducts && topProducts.length > 0) {
            setLocalProducts(topProducts)
        }
        if (!!topLocations && topLocations.length > 0) {
            setLocalStores(topLocations)
        }



    }, [topBrands, topProducts, topLocations, loaded])

    useEffect(() => {
        console.log('here is t: ', t('home:home'))
        // console.log('here are products: ', JSON.stringify(localProducts.slice(0, 3)))
        // console.log('here are services: ', JSON.stringify(services.slice(services.length - 3, services.length)))
        //
        // console.log('here are brands: ', JSON.stringify(localBrands.slice(0, 3)))
        // console.log('here are stores: ', JSON.stringify(localStores.slice(0, 3)))
    }, [localStores, localProducts, localBrands, services])

    useEffect(() => {
        console.log('topBrands: ', topBrands)
    }, [topBrands])

  const HeroSlides = [
    {
      image: require("assets/hero-img-1-2.png"),
      content: (
        <div className="drop-shadow-sm flex flex-col justify-center items-start h-full">
          <h1 className={isMobile ? "text-6xl font-serif text-white mb-7" : "text-8xl font-serif text-white mb-7"}>{t('home:findOut')}</h1>
          <h2 className="text-3xl font-semibold text-white mb-14">{t('home:whatWeCanDo')}</h2>
          <Link href="https://damamap.com/6-benefits-of-cbd-oil/">
            <a>
              <Button
                variant={'contained'} color={'black'} size={'xl'}
                className="normal-case"
                endIcon={
                  <FontAwesomeIcon icon={solid('chevron-right')} className='!h-3.5'/>
                }
              >
                  {t('home:hero_learn_more')}
              </Button>
            </a>
          </Link>
        </div>
      )
    }, {
      image: require("assets/hero-img-1-3.png"),
      content: (
        <div className="drop-shadow-sm flex flex-col justify-center items-start h-full">
          <h1 className={isMobile ? "uppercase text-6xl font-serif text-white mb-7" : "uppercase text-7xl font-serif text-white mb-7"}>{t('home:trusted')}</h1>
          <h2 className="uppercase text-3xl font-semibold text-white mb-14">{t('home:consultant')}</h2>
          <Link href="https://damamaps.com/services/listing/Cannabis License Experts/73">
            <a>
              <Button
                variant={'contained'} color={'black'} size={'xl'}
                className="normal-case"
                endIcon={
                  <FontAwesomeIcon icon={solid('chevron-right')} className='!h-3.5'/>
                }>
                  {t('home:getYourLicense')}
              </Button>
            </a>
          </Link>
        </div>
      )
    }, {
      image: require("assets/hero-img-1-4.png"),
      content: (
        <div className="drop-shadow-sm flex flex-col justify-center items-start h-full">
          <h1 className={isMobile ? "leading-tight text-6xl font-serif text-white mb-7" : "leading-tight text-7xl font-serif text-white mb-7"}>
            <span className={isMobile ? "text-6xl" : "text-8xl"}>{t('home:grow')}</span><br/>
              {t('home:yourBusiness')}<br/>
              {t('home:withUs')}
          </h1>
          <h2 className=" text-3xl font-semibold text-white mb-14">{t('home:listStore')}</h2>
          <Link href="https://damamap.com/en/list-your-stores/">
            <a>
              <Button
                variant={'contained'} color={'black'} size={'xl'}
                className="normal-case"
                endIcon={
                  <FontAwesomeIcon icon={solid('chevron-right')} className='!h-3.5'/>
                }>
                  {t('home:hero_learn_more')}
              </Button>
            </a>
          </Link>
        </div>
      )
    }
  ]

  return (
    <>
    <Head>
            <title>OpenCannabiz.com知麻開門| Home</title>
            <meta name="keywords" content="OpenCannabiz, CBD, Accessories" />
            <meta property="og:title" lang="en" content="OpenCannabiz | Home" />
            <meta property="og:title" lang="zh-Hans"content="知麻開門 | 带你探寻一个神秘领域的生活方式" />
            <meta property="og:image" content="https://damawish-files-bucket-dev.s3.amazonaws.com/DaMaMaps_logo_transparent.png" />
            <meta property="og:description" lang="en" content="OpenCannabiz 知麻開門 is a global technology firm in the cannabis industry with a focus on e-commerce, retail, the Internet, and technology. Established in 2021 in Toronto, Canada , we offer a range of services, including consumer-to-consumer (C2C), business-to-consumer (B2C), and business-to-business (B2B) sales through web portals. Additionally, we provide electronic payment services, shopping search engines, and cloud computing services. 
Our company owns and manages a diverse portfolio of cannabis-related businesses across the globe, spanning various sectors. 
" />
            <meta property="og:description" lang="zh-Hans" content="OpenCannabiz知麻開門是全球华人No.1最大的大麻媒体社交电商平台科技公司，位于加拿大安大略省多伦多市。专注于电子商务与零售的互联网科技平台，致力于遍布全球各个领域与大麻相关的企业投资组合。包括通过网络门户进行消费者对消费者（C2C）、企业对消费者（B2C）和企业对企业（B2B）销售。此外，我们还将提供电子支付服务、购物搜索引擎和人工智能服务。" />
            <meta property="og:url" content="https://opencannabiz.com" />
            <meta name="twitter:card" content="https://damawish-files-bucket-dev.s3.amazonaws.com/DaMaMaps_logo_transparent.png" />
        </Head>
      <HeroSlider
        id='home-hero-slider' className="!h-[80vh] max-h-[800px]"
        slides={HeroSlides} brightness={0.9} loop={true}
      />
      <Wrapper>
        <Container>
            {
                loaded ?
                    <ItemSlider
                        title={t('home:home_featuredstores')}
                        items={localStores}
                        href="/map"
                        render={item => (
                            <LocationCard data={item} t={t} i18n={i18n}/>
                        )}
                    /> :
                    <span>{t('home:loading')}</span>
            }
        </Container>
      </Wrapper>
        <Wrapper>
            <Container>
                {
                    loaded ?
                        <ItemSlider
                            title={t('home:home_featuredbrands')}
                            items={localBrands}
                            href="/brands/discovery"
                            render={item => (
                                <BrandCard data={item}/>
                            )}
                        /> :
                        <span>{t('home:loading')}</span>
                }
            </Container>
        </Wrapper>
        <Wrapper>
            <Container>
                {
                    loaded ?
                        <ItemSlider
                            title={t('home:home_featuredproducts')}
                            items={localProducts}
                            href="/products"
                            render={item => (
                                <ProductCard data={item} type={'Product'}/>
                            )}
                        /> :
                        <span>{t('home:loading')}</span>
                }
            </Container>
        </Wrapper>
      <Wrapper className="pt-6">
        <Container>
          <h2 className="text-2xl font-bold mb-6">
              {t('home:home_newsandeducation')}
          </h2>
          <ItemSlider
            title={t('home:learn_cannabis101')}
            items={learnArticles}
            href="https://damamap.com/law/"
            render={item => (
              <ArticleCard data={item}/>
            )}
          />
        </Container>
        <Container className="mt-16">
          <ItemSlider
            title={t('home:news')}
            items={newsArticles}
            href="https://damamap.com/news/"
            render={item => (
              <ArticleCard data={item}/>
            )}
          />
        </Container>
      </Wrapper>
      {/*<div><h1>hello</h1></div>*/}
    </>
  )
}

Home.getLayout = function getLayout(page) {
  return (
    <DefaultLayout>
      {page}
    </DefaultLayout>
  )
}
