import React, {useEffect, useState} from "react";
import Link from "next/link";
import {Card, CardContent, CardMedia, Typography} from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import cn from "clsx";

export default function BrandCard({data:item, className, row=false, onMouseEnter=()=>{}, margin}) {
    const [follower, setFollower] = useState(item.followers)
    const [liked, setLiked] = useState(item.liked)

    const toggleLiked = () => {
        setLiked(!liked)
        setFollower(liked ? follower - 1 : follower + 1)
    }

    const handleImageError = (e) => {
        e.target.onerror = null;
        e.target.src = `https://dummyimage.com/640x1:1/eeeeee/333333&text=${item.name}`
    }

    useEffect(() => {
        // console.log('item: ', item)
    }, [])

    return (
        <div onMouseEnter={onMouseEnter} className={cn(row&&'flex', className, 'cursor-pointer') }>
            <Link href={`/brands/${encodeURIComponent(item.slug)}/${item.id}`} passHref>
                <Card className="h-340 mb-5 shadow-md rounded overflow-hidden p-4" sx={{
                    marginRight: margin === true ? 5 : 0,
                    // maxWidth: 280,
                    // margin: "0 auto",
                    // padding: "0.1em",
                }}>
                    <div style={{ position: 'relative' }}>
                        <CardMedia
                            component="img"
                            sx={{ height: 262, padding: "1em 1em 0 1em", objectFit: 'contain' }}
                            image={item.logo}
                            alt={encodeURIComponent(item.slug)}
                            onError={handleImageError}
                        />
                        <div onClick={() => toggleLiked()} style={{position: "absolute", top: 10,left: "90%",transform: "translateX(-50%)", cursor: 'pointer'}}>
                            {
                                liked === true ? <FavoriteIcon /> : <FavoriteBorderIcon />
                            }
                        </div>
                    </div>
                    <CardContent>
                        <Typography sx={{ height: 50, fontSize: 16, fontWeight: 700, color: 'black', fontFamily: 'Montserrat' }}>
                            {item.name}
                        </Typography>
                        {/*{*/}
                        {/*    item.followers !== undefined ?*/}
                        {/*        <Typography sx={{ fontSize: 13, fontWeight: 400, color: '#333333', fontFamily: 'Montserrat' }}>*/}
                        {/*            {`Followers: ${follower}`}*/}
                        {/*        </Typography> : null*/}
                        {/*}*/}
                    </CardContent>
                </Card>
            </Link>
        </div>
    )
}
